import Splide from '@splidejs/splide';

const gallertCarousel = document.getElementsByClassName('splide')[0];

export const slider = () => {
    if (gallertCarousel) {
        new Splide('.splide', {
            type: 'loop',
            autoplay: true,
            lazyLoad: true,
            perPage: 3,
            pagination: false,
            autoHeight: true,
            autoWidth: true,
            perMove: 1,
            gap: 20,
            breakpoints: {
                640: {
                    perPage: 1,
                },
                769: {
                    perPage: 2
                },
                960: {
                    perPage: 3
                },
                1400: {
                    perPage: 5
                }
            }
        }).mount();
    }
}
