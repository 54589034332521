export const addClassOnScroll = () => {
    let scrollPosition = window.scrollY;
    const headerLogo = document.getElementsByClassName("header")[0];
    const heroHeight = document.querySelector('section.hero')?.offsetHeight;
    const homePage = document.querySelector('body.home');

    if (homePage) {
        window.addEventListener('scroll', function() {
            scrollPosition = window.scrollY;

            if (scrollPosition >= heroHeight / 2) {
                headerLogo.classList.add('header__scrolled');
            } else {
                headerLogo.classList.remove('header__scrolled');
            }
        })
    }
}
