export const mobileMenu = () => {
    const menuButton = document.getElementsByClassName("hamburger")[0];
    const body = document.body;
    const contactLink = document.querySelector('a[href="#contact"]');

    menuButton.addEventListener('click', () => {
        menuButton.classList.toggle('is-active');
        body.classList.toggle('menu-open');
    });

    contactLink.addEventListener('click', () => {
        if (body.classList.contains('menu-open')) {
            body.classList.remove('menu-open');
            menuButton.classList.remove('is-active');
        }
    })
}
